<template>
  <div class="mod-menu">
   <el-form :inline="true" :model="dataForm">
      <el-form-item>
        <el-button  type="primary" @click="addOrUpdateHandle('add')" size="small">新增</el-button>
      </el-form-item>
    </el-form>
    <div class="tableBox">
       <el-table
        :data="dataList"
        style="width: 100%"
        row-key="id"
        border
        lazy
        height="80vh"
        :load="load"
        :tree-props="{children: 'children', hasChildren: 'leafNode'}">
        <el-table-column
          prop="name"
          label="名称">
        </el-table-column>
        <el-table-column
          prop="id"
          label="编号"
          width="100">
        </el-table-column>
        <el-table-column
          prop="sort"
          label="排序号"
          width="100">
        </el-table-column>
        <el-table-column
          prop="status"
          label="状态"
          align="center"
          width="80">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.status === 2" size="small" type="danger">禁用</el-tag>
            <el-tag v-else size="small">启用</el-tag>
          </template>
        </el-table-column>
        <el-table-column
          fixed="right"
          header-align="center"
          align="center"
          width="150"
          label="操作">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="addOrUpdateHandle('edit', scope.row)">修改</el-button>
            <el-button type="text" size="small" @click="disableOrEnable(scope.row)">{{  scope.row.status === 1 ? '禁用' : '启用'}}</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 弹窗, 新增 / 修改 -->
    <add-or-update ref="addOrUpdate" @refreshDataList="reload"></add-or-update>
  </div>
</template>

<script>
import AddOrUpdate from './details/scq-adress-detail'
export default {
  data () {
    return {
      dataForm: {},
      dataList: [],
      getIndex: -1,
      dataListLoading: false,
      addOrUpdateVisible: false,
      dataMap: new Map()
    }
  },
  components: {
    AddOrUpdate
  },
  activated () {
    // this.getDataList()
    this.dataListLoading = true
    this.$http({
      url: this.$http.adornUrl(`/areaManager/findByParentId/000000`),
      method: 'get',
      params: this.$http.adornParams()
    }).then(({ data }) => {
      if (data) {
        this.dataList = data
      } else {
        this.dataList = []
      }
      this.dataListLoading = false
    })
  },
  methods: {
    reload (parentId) {
      if (!this.dataMap.has(parentId)) {
        this.getDataList()
        return
      }
      const { tree, treeNode, resolve } = this.dataMap.get(parentId)
      this.load(tree, treeNode, resolve)
    },
    load (tree, treeNode, resolve) {
      let parentId = tree.id
      // 将当前选中的节点存起来
      this.dataMap.set(parentId, { tree, treeNode, resolve })
      this.$http({
        url: this.$http.adornUrl(`/areaManager/findByParentId/${parentId}`),
        method: 'get',
        params: this.$http.adornParams()
      }).then(({ data }) => {
        if (data) {
          resolve(data)
        }
      })
    },
    // 禁用或启用
    disableOrEnable (row) {
      let title = row.status === 1 ? '禁用' : '启用'
      this.$confirm('确定对【' + row.name + '】进行【' + title + '】操作?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let status = row.status === 1 ? 2 : 1
        this.$http({
          url: this.$http.adornUrl('/areaManager/updateAndChildren'),
          method: 'post',
          data: {
            id: row.id,
            status: status
          }
        }).then(({ data }) => {
          if (data) {
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 1500,
              onClose: () => {
                row.status = status
                this.getDataList()
              }
            })
          } else {
            this.$message.error('操作失败')
          }
        })
      }).catch(() => {})
    },
    // 获取数据列表
    getDataList () {
      this.dataListLoading = true
      this.$http({
        url: this.$http.adornUrl(`/areaManager/findByParentId/000000`),
        method: 'get',
        params: this.$http.adornParams()
      }).then(({ data }) => {
        if (data) {
          this.dataList = data
        } else {
          this.dataList = []
        }
        this.dataListLoading = false
      })
    },
    // 新增 / 修改
    addOrUpdateHandle (todo, row) {
      this.$nextTick(() => {
        this.$refs.addOrUpdate.init(todo, row)
      })
    },
    // 删除
    deleteHandle (id) {
      this.$confirm(`确定对[id=${id}]进行[删除]操作?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http({
          url: this.$http.adornUrl(`/system/menu/${id}`),
          method: 'delete',
          data: this.$http.adornData()
        }).then(({data}) => {
          if (data) {
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 1500,
              onClose: () => {
                this.getDataList()
              }
            })
          } else {
            this.$message.error(data.message)
          }
        })
      }).catch(() => {})
    }
  }
}
</script>
<style lang="less">
  .el-popover,.el-poppe {
    max-height: 300px;
    overflow: auto;
  }
</style>
